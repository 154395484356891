import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_4 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_5 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_6 = { class: "p-field p-col-6 p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelectBox = _resolveComponent("MultiSelectBox")!

  return (_ctx.surveyCgmInitialFormDto)
    ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refDayToDay",
              modelValue: _ctx.surveyCgmInitialFormDto.dayToDay,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.dayToDay) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.dayToDayOptions,
              title: _ctx.$t('personData.dayToDay.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refDayToDayActivity",
              modelValue: _ctx.surveyCgmInitialFormDto.dayToDayActivity,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.dayToDayActivity) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.dayToDayActivityOptions,
              title: _ctx.$t('personData.dayToDayActivity.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refDayToDaySmoker",
              modelValue: _ctx.surveyCgmInitialFormDto.dayToDaySmoker,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.dayToDaySmoker) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.dayToDaySmokerOptions,
              title: _ctx.$t('personData.dayToDaySmoker.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refDayToDaySmokerQuantity",
              modelValue: _ctx.surveyCgmInitialFormDto.dayToDaySmokerQuantity,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.dayToDaySmokerQuantity) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.dayToDaySmokerQuantityOptions,
              title: _ctx.$t('personData.dayToDaySmokerQuantity.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}