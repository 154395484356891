
import { defineComponent, onMounted, ref } from 'vue';
import CardComponent from '@/components/CardComponent.vue';
import { patientService } from '@/services/PatientService';
import { ServiceError } from '@/services/util/ServiceError';
import { useRoute } from 'vue-router';
import { SurveyCgmInitial, SurveyCgmInitialFormDto, SurveyType } from '@/models/Survey';
import SportTab from './habitsTabs/SportTab.vue';
import FoodTab from './habitsTabs/FoodTab.vue';
import DayToDayTab from './habitsTabs/DayToDayTab.vue';
import RestTab from './habitsTabs/RestTab.vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';

export default defineComponent({
  components: { CardComponent, SportTab, FoodTab, DayToDayTab, RestTab },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const waitSubmit = ref(false);
    const editMode = ref(false);
    const submitting = ref(false);
    const patientId = Array.isArray(route.params.patientId) ? route.params.patientId[0] : route.params.patientId;
    const surveyCgmInitialDefault = ref<SurveyCgmInitial>();
    const surveyCgmInitialFormDto = ref<SurveyCgmInitialFormDto>();
    const refSportTab = ref();
    const refFoodTab = ref();
    const refDayToDayTab = ref();
    const refRestTab = ref();
    const toast = useToast();

    onMounted(async () => {
      await fetchCgmInitialSurvey();
    });

    const fetchCgmInitialSurvey = async () => {
      const result = await patientService.findSurveyCgmInitial(patientId);
      if (!(result instanceof ServiceError)) {
        surveyCgmInitialDefault.value = { ...result };
        surveyCgmInitialFormDto.value = new SurveyCgmInitialFormDto(surveyCgmInitialDefault.value);
        if (!surveyCgmInitialFormDto.value.id) {
          surveyCgmInitialFormDto.value.id = SurveyType.CGM_INITIAL;
        }
      }
    };

    const onSubmit = async () => {
      if (surveyCgmInitialFormDto.value) {
        try {
          waitSubmit.value = true;
          await patientService.modifySurveyCgmInitial(patientId, surveyCgmInitialFormDto.value.toSurveyCgmInitial());
          toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditPatient')}`, life: 3000 });
        } catch (error) {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorEditPatient')} ${t('messages.notifications.tryLater')}`,
            life: 3000,
          });
        } finally {
          waitSubmit.value = false;
          editMode.value = false;
        }
      }
    };

    const onCancel = async () => {
      editMode.value = false;
      refSportTab.value?.setInitialValues();
      refFoodTab.value?.setInitialValues();
      refRestTab.value?.setInitialValues();
      refDayToDayTab.value?.setInitialValues();
    };

    const onInfoChanged = (modelUpdate: SurveyCgmInitialFormDto) => {
      if (surveyCgmInitialFormDto.value !== undefined) {
        surveyCgmInitialFormDto.value = modelUpdate;
      }
    };

    return {
      editMode,
      submitting,
      surveyCgmInitialFormDto,
      onSubmit,
      onCancel,
      surveyCgmInitialDefault,
      onInfoChanged,
      refSportTab,
      refFoodTab,
      refDayToDayTab,
      refRestTab,
    };
  },
});
