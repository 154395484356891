
import { computed, defineComponent, onMounted, watch, ref } from 'vue';
import { SurveyCgmInitial, SurveyCgmInitialFormDto, DayToDay, DayToDayActivity, DayToDaySmoke } from '@/models/Survey';
import { useI18n } from 'vue-i18n';
import MultiSelectBox from '@/components/common/MultiSelectBox.vue';
import { PatientInfoItem } from '@/models/PatientInfo';
import SurveyHelper from '@/helpers/Surveys.helper';

export default defineComponent({
  components: { MultiSelectBox },
  emits: ['onInfoChanged'],
  props: {
    surveyCgmInitialDefault: {
      type: Object as () => SurveyCgmInitial,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const surveyCgmInitialFormDto = ref(new SurveyCgmInitialFormDto(props.surveyCgmInitialDefault));

    const refDayToDay = ref();
    const refDayToDayActivity = ref();
    const refDayToDaySmoker = ref();
    const refDayToDaySmokerQuantity = ref();

    onMounted(async () => {});

    const dayToDayOptions = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.dayToDay',
          DayToDay,
          props.surveyCgmInitialDefault.answers?.DAY_TO_DAY,
        );
      } else {
        return [];
      }
    });

    const dayToDayActivityOptions = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.dayToDayActivity',
          DayToDayActivity,
          props.surveyCgmInitialDefault.answers?.DAY_TO_DAY_ACTIVITY,
        );
      } else {
        return [];
      }
    });

    const dayToDaySmokerOptions = computed<PatientInfoItem[]>(() =>
      SurveyHelper.generateYesNoOptions('DAY_TO_DAY_SMOKER', props.surveyCgmInitialDefault.answers, t),
    );

    const dayToDaySmokerQuantityOptions = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.dayToDaySmokerQuantity',
          DayToDaySmoke,
          props.surveyCgmInitialDefault.answers?.DAY_TO_DAY_SMOKER_QUANTITY,
        );
      } else {
        return [];
      }
    });

    const setInitialValues = () => {
      if (props.surveyCgmInitialDefault) {
        surveyCgmInitialFormDto.value = new SurveyCgmInitialFormDto(props.surveyCgmInitialDefault);
        resetAllMultiSelect();
      }
    };

    const resetAllMultiSelect = () => {
      refDayToDay.value?.setInitialValues();
      refDayToDayActivity.value?.setInitialValues();
      refDayToDaySmoker.value?.setInitialValues();
      refDayToDaySmokerQuantity.value?.setInitialValues();
    };

    watch(surveyCgmInitialFormDto.value, async (newValue) => {
      if (props.editMode) {
        emit('onInfoChanged', newValue);
      }
    });

    return {
      surveyCgmInitialFormDto,
      dayToDayOptions,
      dayToDayActivityOptions,
      dayToDaySmokerOptions,
      dayToDaySmokerQuantityOptions,
      refDayToDay,
      refDayToDayActivity,
      refDayToDaySmoker,
      refDayToDaySmokerQuantity,
      setInitialValues,
    };
  },
});
