import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_4 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_5 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_6 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_7 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_8 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_9 = { for: "restSupplementDescription" }
const _hoisted_10 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_11 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_12 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_13 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_14 = { class: "p-field p-col-6 p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelectBox = _resolveComponent("MultiSelectBox")!
  const _component_HealzInput = _resolveComponent("HealzInput")!

  return (_ctx.surveyCgmInitialFormDto)
    ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refRestHours",
              modelValue: _ctx.surveyCgmInitialFormDto.restHours,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.restHours) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.restHoursOptions,
              title: _ctx.$t('personData.restHours.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refRestWakeUpTired",
              modelValue: _ctx.surveyCgmInitialFormDto.restWakeUpTired,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.restWakeUpTired) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.restWakeUpTiredOptions,
              title: _ctx.$t('personData.restWakeUpTired.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refRestDayEnergy",
              modelValue: _ctx.surveyCgmInitialFormDto.restDayEnergy,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.restDayEnergy) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.restDayEnergyOptions,
              title: _ctx.$t('personData.restDayEnergy.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refRestPattern",
              modelValue: _ctx.surveyCgmInitialFormDto.restPattern,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.restPattern) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.restPatternOptions,
              title: _ctx.$t('personData.restPattern.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refRestSupplement",
              modelValue: _ctx.surveyCgmInitialFormDto.restSupplement,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.restSupplement) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.restSupplementOptions,
              title: _ctx.$t('personData.restSupplement.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('personData.restSupplementDescription.title')), 1),
            _createVNode(_component_HealzInput, {
              id: "restSupplementDescription",
              modelValue: _ctx.surveyCgmInitialFormDto.restSupplementDescription,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.restSupplementDescription) = $event)),
              editable: _ctx.editMode,
              type: "text"
            }, null, 8, ["modelValue", "editable"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refRestStimulating",
              modelValue: _ctx.surveyCgmInitialFormDto.restStimulating,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.restStimulating) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.restStimulatingOptions,
              title: _ctx.$t('personData.restStimulating.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refRestCafesPerDay",
              modelValue: _ctx.surveyCgmInitialFormDto.restCafesPerDay,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.restCafesPerDay) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.restCafesPerDayOptions,
              title: _ctx.$t('personData.restCafesPerDay.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refRestTeasPerDay",
              modelValue: _ctx.surveyCgmInitialFormDto.restTeasPerDay,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.restTeasPerDay) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.restTeasPerDayOptions,
              title: _ctx.$t('personData.restTeasPerDay.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refRestEnergyDrinksPerDay",
              modelValue: _ctx.surveyCgmInitialFormDto.restEnergyDrinksPerDay,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.restEnergyDrinksPerDay) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.restEnergyDrinksPerDayOptions,
              title: _ctx.$t('personData.restEnergyDrinksPerDay.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refRestScreens",
              modelValue: _ctx.surveyCgmInitialFormDto.restScreens,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.restScreens) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.restScreensOptions,
              title: _ctx.$t('personData.restScreens.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}