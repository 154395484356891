import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_SportTab = _resolveComponent("SportTab")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_FoodTab = _resolveComponent("FoodTab")!
  const _component_DayToDayTab = _resolveComponent("DayToDayTab")!
  const _component_RestTab = _resolveComponent("RestTab")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_CardComponent, {
    cardTitle: _ctx.$t('personData.habitsPatient'),
    "edit-mode": _ctx.editMode
  }, {
    headingActions: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_component_Button, {
        class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editMode = !_ctx.editMode))
      }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: "icon-edit",
            "aria-label": _ctx.$t('common.edit'),
            "aria-hidden": "true"
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["class"])), [
        [
          _directive_tooltip,
          _ctx.$t('common.edit'),
          void 0,
          { bottom: true }
        ]
      ])
    ]),
    cardContent: _withCtx(() => [
      (_ctx.surveyCgmInitialDefault)
        ? (_openBlock(), _createBlock(_component_TabView, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_TabPanel, {
                header: _ctx.$t('personData.habits.sport'),
                disabled: _ctx.editMode
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SportTab, {
                    ref: "refSportTab",
                    "survey-cgm-initial-default": _ctx.surveyCgmInitialDefault,
                    "edit-mode": _ctx.editMode,
                    onOnInfoChanged: _ctx.onInfoChanged
                  }, null, 8, ["survey-cgm-initial-default", "edit-mode", "onOnInfoChanged"])
                ]),
                _: 1
              }, 8, ["header", "disabled"]),
              _createVNode(_component_TabPanel, {
                header: _ctx.$t('personData.habits.food'),
                disabled: _ctx.editMode
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FoodTab, {
                    ref: "refFoodTab",
                    "survey-cgm-initial-default": _ctx.surveyCgmInitialDefault,
                    "edit-mode": _ctx.editMode,
                    onOnInfoChanged: _ctx.onInfoChanged
                  }, null, 8, ["survey-cgm-initial-default", "edit-mode", "onOnInfoChanged"])
                ]),
                _: 1
              }, 8, ["header", "disabled"]),
              _createVNode(_component_TabPanel, {
                header: _ctx.$t('personData.habits.dayToDay'),
                disabled: _ctx.editMode
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DayToDayTab, {
                    ref: "refDayToDayTab",
                    "survey-cgm-initial-default": _ctx.surveyCgmInitialDefault,
                    "edit-mode": _ctx.editMode,
                    onOnInfoChanged: _ctx.onInfoChanged
                  }, null, 8, ["survey-cgm-initial-default", "edit-mode", "onOnInfoChanged"])
                ]),
                _: 1
              }, 8, ["header", "disabled"]),
              _createVNode(_component_TabPanel, {
                header: _ctx.$t('personData.habits.rest'),
                disabled: _ctx.editMode
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_RestTab, {
                    ref: "refRestTab",
                    "survey-cgm-initial-default": _ctx.surveyCgmInitialDefault,
                    "edit-mode": _ctx.editMode,
                    onOnInfoChanged: _ctx.onInfoChanged
                  }, null, 8, ["survey-cgm-initial-default", "edit-mode", "onOnInfoChanged"])
                ]),
                _: 1
              }, 8, ["header", "disabled"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    footerActions: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-secondary p-button-medium",
        label: _ctx.$t('common.cancel'),
        onClick: _ctx.onCancel
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_Button, {
        class: "p-button p-button-info p-button-medium",
        loading: _ctx.submitting,
        label: _ctx.$t('common.save'),
        onClick: _ctx.onSubmit
      }, null, 8, ["loading", "label", "onClick"])
    ]),
    _: 1
  }, 8, ["cardTitle", "edit-mode"]))
}