
import { computed, defineComponent, onMounted, watch, ref } from 'vue';
import {
  Diets,
  FastHours,
  NumberOfMeals,
  DietSnacks,
  DigestiveProblems,
  WaterQuantity,
  SurveyCgmInitial,
  SurveyCgmInitialFormDto,
} from '@/models/Survey';
import { useI18n } from 'vue-i18n';
import MultiSelectBox from '@/components/common/MultiSelectBox.vue';
import HealzInput from '@/components/healz/ui/HealzInput.vue';
import { PatientInfoItem } from '@/models/PatientInfo';
import SurveyHelper from '@/helpers/Surveys.helper';
import MultiCheckBox from '@/components/common/MultiCheckBox.vue';

export default defineComponent({
  components: { MultiSelectBox, HealzInput, MultiCheckBox },
  emits: ['onInfoChanged'],
  props: {
    surveyCgmInitialDefault: {
      type: Object as () => SurveyCgmInitial,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const surveyCgmInitialFormDto = ref(new SurveyCgmInitialFormDto(props.surveyCgmInitialDefault));

    const refCurrentDiet = ref();
    const refCurrentDietDescription = ref();
    const refCurrentDietNumberOfMeals = ref();
    const refCurrentDietSnack = ref();
    const refCurrentDietSnackDescription = ref();
    const refCurrentDietFast = ref();
    const refCurrentDietFastHours = ref();
    const refCurrentDietDigestiveProblems = ref();
    const refCurrentDietDigestiveProblemsDescription = ref();
    const refCurrentDietSupplement = ref();
    const refCurrentDietAllergy = ref();
    const refCurrentDietWaterQuantity = ref();

    onMounted(async () => {});

    const currentDietOptions = computed<PatientInfoItem[]>(() =>
      SurveyHelper.generateYesNoOptions('CURRENT_DIET', props.surveyCgmInitialDefault.answers, t),
    );

    const currentDietDescriptionOptions = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.currentDietDescription',
          Diets,
          props.surveyCgmInitialDefault.answers?.CURRENT_DIET_DESCRIPTION,
        );
      } else {
        return [];
      }
    });

    const currentDietNumberOfMealsOptions = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.currentDietNumberOfMeals',
          NumberOfMeals,
          props.surveyCgmInitialDefault.answers?.CURRENT_DIET_NUMBER_OF_MEALS,
        );
      } else {
        return [];
      }
    });

    const currentDietSnackOptions = computed<PatientInfoItem[]>(() =>
      SurveyHelper.generateYesNoOptions('CURRENT_DIET_SNACK', props.surveyCgmInitialDefault.answers, t),
    );

    const currentDietSnackDescriptionOptions = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.currentDietSnackDescription',
          DietSnacks,
          props.surveyCgmInitialDefault.answers?.CURRENT_DIET_SNACK_DESCRIPTION,
        );
      } else {
        return [];
      }
    });

    const currentDietFastOptions = computed<PatientInfoItem[]>(() =>
      SurveyHelper.generateYesNoOptions('CURRENT_DIET_FAST', props.surveyCgmInitialDefault.answers, t),
    );

    const currentDietFastHoursOptions = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.currentDietFastHours',
          FastHours,
          props.surveyCgmInitialDefault.answers?.CURRENT_DIET_FAST_HOURS,
        );
      } else {
        return [];
      }
    });

    const currentDietDigestiveProblemsOptions = computed<PatientInfoItem[]>(() =>
      SurveyHelper.generateYesNoOptions('CURRENT_DIET_DIGESTIVE_PROBLEMS', props.surveyCgmInitialDefault.answers, t),
    );

    const currentDietDigestiveProblemsDescriptionOptions = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return Object.keys(DigestiveProblems).map((k) => ({
          id: k,
          text: t(`personData.currentDietDigestiveProblemsDescription.${k}`),
          value:
            props.surveyCgmInitialDefault?.answers &&
            props.surveyCgmInitialDefault?.answers.CURRENT_DIET_DIGESTIVE_PROBLEMS_DESCRIPTION &&
            props.surveyCgmInitialDefault?.answers.CURRENT_DIET_DIGESTIVE_PROBLEMS_DESCRIPTION?.indexOf(k) != -1,
        }));
      } else {
        return [];
      }
    });

    const currentDietSupplementOptions = computed<PatientInfoItem[]>(() =>
      SurveyHelper.generateYesNoOptions('CURRENT_DIET_SUPPLEMENT', props.surveyCgmInitialDefault.answers, t),
    );

    const currentDietAllergyOptions = computed<PatientInfoItem[]>(() =>
      SurveyHelper.generateYesNoOptions('CURRENT_DIET_ALLERGY', props.surveyCgmInitialDefault.answers, t),
    );

    const currentDietWaterQuantityOptions = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.currentDietWaterQuantity',
          WaterQuantity,
          props.surveyCgmInitialDefault.answers?.CURRENT_DIET_WATER_QUANTITY,
        );
      } else {
        return [];
      }
    });

    const setInitialValues = () => {
      if (props.surveyCgmInitialDefault) {
        surveyCgmInitialFormDto.value = new SurveyCgmInitialFormDto(props.surveyCgmInitialDefault);
        resetAllMultiSelect();
      }
    };

    const resetAllMultiSelect = () => {
      refCurrentDiet.value?.setInitialValues();
      refCurrentDietDescription.value?.setInitialValues();
      refCurrentDietNumberOfMeals.value?.setInitialValues();
      refCurrentDietSnack.value?.setInitialValues();
      refCurrentDietSnackDescription.value?.setInitialValues();
      refCurrentDietFast.value?.setInitialValues();
      refCurrentDietFastHours.value?.setInitialValues();
      refCurrentDietDigestiveProblems.value?.setInitialValues();
      refCurrentDietDigestiveProblemsDescription.value?.setInitialValues();
      refCurrentDietSupplement.value?.setInitialValues();
      refCurrentDietAllergy.value?.setInitialValues();
      refCurrentDietWaterQuantity.value?.setInitialValues();
    };

    watch(surveyCgmInitialFormDto.value, async (newValue) => {
      if (props.editMode) {
        emit('onInfoChanged', newValue);
      }
    });

    return {
      surveyCgmInitialFormDto,
      currentDietOptions,
      currentDietDescriptionOptions,
      currentDietNumberOfMealsOptions,
      currentDietSnackOptions,
      currentDietSnackDescriptionOptions,
      currentDietFastOptions,
      currentDietFastHoursOptions,
      currentDietDigestiveProblemsOptions,
      currentDietDigestiveProblemsDescriptionOptions,
      currentDietSupplementOptions,
      currentDietAllergyOptions,
      currentDietWaterQuantityOptions,
      refCurrentDiet,
      refCurrentDietDescription,
      refCurrentDietNumberOfMeals,
      refCurrentDietSnack,
      refCurrentDietSnackDescription,
      refCurrentDietFast,
      refCurrentDietFastHours,
      refCurrentDietDigestiveProblems,
      refCurrentDietDigestiveProblemsDescription,
      refCurrentDietSupplement,
      refCurrentDietAllergy,
      refCurrentDietWaterQuantity,
      setInitialValues,
    };
  },
});
