
import { computed, defineComponent, onMounted, watch, ref } from 'vue';
import {
  RestHours,
  RestDayEnergy,
  RestPatterns,
  DrinksPerDay,
  SurveyCgmInitial,
  SurveyCgmInitialFormDto,
} from '@/models/Survey';
import { useI18n } from 'vue-i18n';
import MultiSelectBox from '@/components/common/MultiSelectBox.vue';
import HealzInput from '@/components/healz/ui/HealzInput.vue';
import SurveyHelper from '@/helpers/Surveys.helper';
import { PatientInfoItem } from '@/models/PatientInfo';

export default defineComponent({
  components: { MultiSelectBox, HealzInput },
  emits: ['onInfoChanged'],
  props: {
    surveyCgmInitialDefault: {
      type: Object as () => SurveyCgmInitial,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const surveyCgmInitialFormDto = ref(new SurveyCgmInitialFormDto(props.surveyCgmInitialDefault));

    const refRestHours = ref();
    const refRestWakeUpTired = ref();
    const refRestDayEnergy = ref();
    const refRestPattern = ref();
    const refRestSupplement = ref();
    const refRestStimulating = ref();
    const refRestCafesPerDay = ref();
    const refRestTeasPerDay = ref();
    const refRestEnergyDrinksPerDay = ref();
    const refRestScreens = ref();

    onMounted(async () => {});

    const restHoursOptions = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.restHours',
          RestHours,
          props.surveyCgmInitialDefault.answers?.REST_HOURS,
        );
      } else {
        return [];
      }
    });
    const restWakeUpTiredOptions = computed<PatientInfoItem[]>(() =>
      SurveyHelper.generateYesNoOptions('REST_WAKE_UP_TIRED', props.surveyCgmInitialDefault.answers, t),
    );

    const restDayEnergyOptions = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.restDayEnergy',
          RestDayEnergy,
          props.surveyCgmInitialDefault.answers?.REST_DAY_ENERGY,
        );
      } else {
        return [];
      }
    });

    const restPatternOptions = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.restPattern',
          RestPatterns,
          props.surveyCgmInitialDefault.answers?.REST_PATTERN,
        );
      } else {
        return [];
      }
    });

    const restSupplementOptions = computed<PatientInfoItem[]>(() =>
      SurveyHelper.generateYesNoOptions('REST_SUPPLEMENT', props.surveyCgmInitialDefault.answers, t),
    );

    const restStimulatingOptions = computed<PatientInfoItem[]>(() =>
      SurveyHelper.generateYesNoOptions('REST_STIMULATING', props.surveyCgmInitialDefault.answers, t),
    );

    const restCafesPerDayOptions = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.restCafesPerDay',
          DrinksPerDay,
          props.surveyCgmInitialDefault.answers?.REST_CAFES_PER_DAY,
        );
      } else {
        return [];
      }
    });

    const restTeasPerDayOptions = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.restTeasPerDay',
          DrinksPerDay,
          props.surveyCgmInitialDefault.answers?.REST_TEAS_PER_DAY,
        );
      } else {
        return [];
      }
    });

    const restEnergyDrinksPerDayOptions = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.restEnergyDrinksPerDay',
          DrinksPerDay,
          props.surveyCgmInitialDefault.answers?.REST_ENERGY_DRINKS_PER_DAY,
        );
      } else {
        return [];
      }
    });

    const restScreensOptions = computed<PatientInfoItem[]>(() =>
      SurveyHelper.generateYesNoOptions('REST_SCREENS', props.surveyCgmInitialDefault.answers, t),
    );

    const setInitialValues = () => {
      if (props.surveyCgmInitialDefault) {
        surveyCgmInitialFormDto.value = new SurveyCgmInitialFormDto(props.surveyCgmInitialDefault);
        resetAllMultiSelect();
      }
    };

    const resetAllMultiSelect = () => {
      refRestHours.value?.setInitialValues();
      refRestWakeUpTired.value?.setInitialValues();
      refRestDayEnergy.value?.setInitialValues();
      refRestPattern.value?.setInitialValues();
      refRestSupplement.value?.setInitialValues();
      refRestStimulating.value?.setInitialValues();
      refRestCafesPerDay.value?.setInitialValues();
      refRestTeasPerDay.value?.setInitialValues();
      refRestEnergyDrinksPerDay.value?.setInitialValues();
      refRestScreens.value?.setInitialValues();
    };

    watch(surveyCgmInitialFormDto.value, async (newValue) => {
      if (props.editMode) {
        emit('onInfoChanged', newValue);
      }
    });

    return {
      surveyCgmInitialFormDto,
      restHoursOptions,
      restWakeUpTiredOptions,
      restDayEnergyOptions,
      restPatternOptions,
      restSupplementOptions,
      restStimulatingOptions,
      restCafesPerDayOptions,
      restTeasPerDayOptions,
      restEnergyDrinksPerDayOptions,
      restScreensOptions,
      refRestHours,
      refRestWakeUpTired,
      refRestDayEnergy,
      refRestPattern,
      refRestSupplement,
      refRestStimulating,
      refRestCafesPerDay,
      refRestTeasPerDay,
      refRestEnergyDrinksPerDay,
      refRestScreens,
      setInitialValues,
    };
  },
});
