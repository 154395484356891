
import { computed, defineComponent, onMounted, watch, ref } from 'vue';
import {
  SportCardioDays,
  SportStrengthDays,
  SportHourPerDay,
  SurveyCgmInitial,
  SurveyCgmInitialFormDto,
} from '@/models/Survey';
import { useI18n } from 'vue-i18n';
import MultiSelectBox from '@/components/common/MultiSelectBox.vue';
import HealzInput from '@/components/healz/ui/HealzInput.vue';
import { PatientInfoItem } from '@/models/PatientInfo';
import SurveyHelper from '@/helpers/Surveys.helper';

export default defineComponent({
  components: { MultiSelectBox, HealzInput },
  emits: ['onInfoChanged'],
  props: {
    surveyCgmInitialDefault: {
      type: Object as () => SurveyCgmInitial,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const surveyCgmInitialFormDto = ref(new SurveyCgmInitialFormDto(props.surveyCgmInitialDefault));

    const refSport = ref();
    const refSportStrengthDays = ref();
    const refSportCardioDays = ref();
    const refSportHourPerDay = ref();

    onMounted(async () => {});

    const sportOptions = computed<PatientInfoItem[]>(() =>
      SurveyHelper.generateYesNoOptions('SPORT', props.surveyCgmInitialDefault.answers, t),
    );

    const sportHourPerDay = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.sportHourPerDay',
          SportHourPerDay,
          props.surveyCgmInitialDefault.answers?.SPORT_HOUR_PER_DAY,
        );
      } else {
        return [];
      }
    });

    const sportStrengthDays = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.sportStrengthDays',
          SportStrengthDays,
          props.surveyCgmInitialDefault.answers?.SPORT_STRENGTH_DAYS,
        );
      } else {
        return [];
      }
    });

    const sportCardioDays = computed(() => {
      if (props.surveyCgmInitialDefault !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return SurveyHelper.createOptionsObject(
          'personData.sportCardioDays',
          SportCardioDays,
          props.surveyCgmInitialDefault.answers?.SPORT_CARDIO_DAYS,
        );
      } else {
        return [];
      }
    });

    const setInitialValues = () => {
      if (props.surveyCgmInitialDefault) {
        surveyCgmInitialFormDto.value = new SurveyCgmInitialFormDto(props.surveyCgmInitialDefault);
        resetAllMultiSelect();
      }
    };

    const resetAllMultiSelect = () => {
      refSport.value?.setInitialValues();
      refSportCardioDays.value?.setInitialValues();
      refSportHourPerDay.value?.setInitialValues();
      refSportStrengthDays.value?.setInitialValues();
    };

    watch(surveyCgmInitialFormDto.value, async (newValue) => {
      if (props.editMode) {
        emit('onInfoChanged', newValue);
      }
    });

    return {
      surveyCgmInitialFormDto,
      sportOptions,
      sportHourPerDay,
      sportStrengthDays,
      sportCardioDays,
      refSport,
      refSportStrengthDays,
      refSportCardioDays,
      refSportHourPerDay,
      setInitialValues,
    };
  },
});
