import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_4 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_5 = { for: "sportDayTime" }
const _hoisted_6 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_7 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_8 = { class: "p-field p-col-6 p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelectBox = _resolveComponent("MultiSelectBox")!
  const _component_HealzInput = _resolveComponent("HealzInput")!

  return (_ctx.surveyCgmInitialFormDto)
    ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refSport",
              modelValue: _ctx.surveyCgmInitialFormDto.sport,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.sport) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.sportOptions,
              title: _ctx.$t('personData.sport.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('personData.sportDayTime.title')), 1),
            _createVNode(_component_HealzInput, {
              id: "sportDayTime",
              modelValue: _ctx.surveyCgmInitialFormDto.sportDayTime,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.sportDayTime) = $event)),
              editable: _ctx.editMode,
              type: "text"
            }, null, 8, ["modelValue", "editable"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refSportStrengthDays",
              modelValue: _ctx.surveyCgmInitialFormDto.sportStrengthDays,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.sportStrengthDays) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.sportStrengthDays,
              title: _ctx.$t('personData.sportStrengthDays.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refSportCardioDays",
              modelValue: _ctx.surveyCgmInitialFormDto.sportCardioDays,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.sportCardioDays) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.sportCardioDays,
              title: _ctx.$t('personData.sportCardioDays.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_MultiSelectBox, {
              ref: "refSportHourPerDay",
              modelValue: _ctx.surveyCgmInitialFormDto.sportHourPerDay,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.sportHourPerDay) = $event)),
              editMode: _ctx.editMode,
              options: _ctx.sportHourPerDay,
              title: _ctx.$t('personData.sportHourPerDay.title')
            }, null, 8, ["modelValue", "editMode", "options", "title"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}